import { AfterViewInit, Component, HostListener, OnDestroy } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './data/services/auth.service';
import { NotificationService } from './data/services/notification.service';
import { ConfigService } from './data/services/config.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title="ges";

  // Main Properties
  isFullWidth: boolean = true;
  showTemplate: boolean = false;
  urlActive: string;
  checkUrl: any;
  currentYear: number = new Date().getFullYear();

  // Subscriptions
  private routeSubscription: Subscription;

  // Constructor
  constructor(
    private router: Router,
    public translate: TranslateService,
    public notification: NotificationService,
    private localeService: BsLocaleService,
    private auth: AuthService,
    private config: ConfigService
  ) {
    this.showTemplate = this.auth.loggedIn();
    this.initTranslate();
  }

  // CheckIfIsFullWidth:  After view Init;
  ngAfterViewInit() {
    this.checkIfIsFullWidth();
  }

  // Init Traslate System
  initTranslate() {
    // Set languages on Translate system
    this.translate.addLangs(['es', 'cat', 'eus', 'gal',  'en', 'val']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    // Get and Set Browser Language
    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('language') !== null) {
      this.translate.use(localStorage.getItem('language'));
      this.localeService.use(localStorage.getItem('language'));
    } else {
      // this.translate.use(browserLang.match(/es|cat|eus|gal|en|val/) ? browserLang : 'es');
      this.getDomainName();
    }
  }

  getDomainName() {
    this.checkUrl = window.location.hostname.split('.');
    const extension = this.checkUrl[this.checkUrl.length - 1];
    const subdomain = this.checkUrl[0];
    this.detectLanguage(extension, subdomain);
  }

  detectLanguage(language, subdomain) {
    if (language === 'com' && subdomain === 'eval') {
      this.translate.use('es');
      this.localeService.use('es');
      localStorage.setItem('language', 'es');
    } else if (language === 'com' && subdomain === 'aval') {
      this.translate.use('val');
      this.localeService.use('val');
      localStorage.setItem('language', 'val');
    } else if (this.ifIsInArray(language, this.translate.langs)) {
      this.translate.use(language);
      this.localeService.use(language);
      localStorage.setItem('language', language);
    } else {
      this.translate.use('es');
      this.localeService.use('es');
      localStorage.setItem('language', 'es');
    }
  }

  // Check if screen is full width or not show sidebar
  checkIfIsFullWidth() {
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        this.showTemplate = this.auth.loggedIn();
        localStorage.setItem('previusPage', this.urlActive);
        this.urlActive = event.urlAfterRedirects;
        if (this.urlActive.includes('login') || this.urlActive.includes('auth')) {
          this.isFullWidth = true;
          this.showTemplate = false;
        } else {
          this.isFullWidth = false;
        }
      }
    });
  }


  ifIsInArray(param, arr) {
    for (const item of arr) {
      if (item === param) {
        return true;
      }
    }

    return false;
  }

  @HostListener('window:resize', [])
  onResize() {
    if (this.config.enjoyhint_instance) {
      this.config.enjoyhint_instance.resume();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.config.enjoyhint_instance) {
      this.config.enjoyhint_instance.resume();
    }
  }

  // ngOnDestroy
  ngOnDestroy() {
    if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
  }

}
