<div class="exam-preview-content">
  <div class="loader animated fadeIn" *ngIf="loading">
    <img src="./assets/icons/spinner-solid.svg" />
  </div>
  <div class="title-container" *ngIf="!loading && exam && exam.id">
    <h2>{{ 'EXAMS.PREVIEW.TITLE' | translate }}</h2>
  </div>
  <div class="examtype-selector">
    <div class="pattern_item" *ngIf="!loading && exam && exam.id">
      <div class="index_counter" style="background: #f3075f">
      </div>
      <span>{{ exam.title }}</span>
    </div>
  </div>
  <div class="exam_preview" *ngIf="!loading && exam && exam.id">
    <div class="pattern-card">
      <div *ngIf="imgPerfil" class="pattern_item box" [ngStyle]="{'padding': 0}">
        <img class="img-perfil" [src]="getSafeUrl()"/>
      </div>
      <div class="letters mr-3" [ngClass]="{'ml-3': imgPerfil}">
        <div class="pattern_item title bg_grey">
          <input class="no_style" type="text" placeholder="{{ 'EXAMS.ITEM.NAME_SCHOOL' | translate }}" [(ngModel)]="exam.schoolName">
        </div>
        <div class="title">
          <span>{{ 'EXAMS.ITEM.LASTNAME' | translate }}</span><span class="name-line"></span>
        </div>
        <div class="title">
          <span>{{ 'EXAMS.ITEM.NAME' | translate }}</span><span class="name-line"></span>
        </div>
        <div class="subject-date-container pattern_item no-gutters title bg_grey">
          <div class="subject-column">
            <div>{{ exam.area + " - " + exam.classGroup.split("-")[0] }}</div>
          </div>
          <div class="date-column">
            {{ 'EXAMS.ITEM.DATE' | translate }}:
            <div class="input_container datepicker">
              {{ exam && exam.date ? exam.date : '' }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="pattern_item box">
        <label class="title-box">{{ 'EXAMS.ITEM.CALIFICATION' | translate }}</label>
      </div> -->
    </div>
    <br />
    <div class="question-list" *ngIf="exam.examQuestions && exam.examQuestions.length > 0">
      <div class="question-item" *ngFor="let question of exam.examQuestions">
        <div class="question-header">
          <span class="question-title">{{ question.questionOrder }}.-</span>
          <h4 class="question-title" [ngClass]="{'primary': gradeId && gradeId === 1 }">{{ question.question.title }}</h4>
        </div>
        <div class="question_content" [ngClass]="{'primary': gradeId && gradeId === 1 }">
          <div [innerHtml]="question.question.content | safeHtml"></div>
        </div>
      </div>
    </div>
    <h4 *ngIf="!exam.examQuestions || exam.examQuestions.length <= 0">{{ 'EXAMS.PREVIEW.NON_QUESTION_EXAM' | translate }}</h4>
  </div>
  <br>
  <div class="row" *ngIf="!loading && exam && exam.id">
    <div class="col-md-6">
      <button class="btn_purple btn-knowledge" (click)="obtainCurriculum()">{{ 'EXAMS.PREVIEW.ASSOCIATE_KNOWLEDGES' | translate }}</button>
    </div>
    <div class="col-md-3">
      <button class="btn_grey btn-pdf" (click)="openModalDownload()">
        <img class="icon-print mr-2" style="width: 26px;" [src]="'./assets/icons/printer_icon.svg'" [alt]="'Printer icon'" />
        {{ 'EXAMS.PREVIEW.DOWNLOAD' | translate }}
      </button>
    </div>
    <div class="col-md-3">
      <button class="btn_pink btn-back" [routerLink]="['/exam']">{{ 'EXAMS.PREVIEW.BACK_LIST' | translate }}</button>
    </div>
  </div>
</div>

<ng-template #curriculumModalContent>
  <div class="row">
    <div class="col-12">
      <!-- acordion start -->
      <div *ngIf="selectedCurriculums && selectedCurriculums.length > 0" class="accordion curriculums_list" id="accordionSelected">
          <div class="card card-accordion"  *ngFor="let item of selectedCurriculums">
            <div class="card-header d-flex align-items-center justify-content-start" [id]="'heading-'+item.id">
                <div *ngIf="item.basicKnowledge && item.basicKnowledge.code" class="pointer btn btn-link collapsed" data-toggle="collapse" [attr.data-target]="'#collapse'+item.id" aria-expanded="false" [attr.aria-controls]="'collapse'+item.id" (click)="showAccordion('#accordionSelected', item.id)">
                    <strong>{{ item.basicKnowledge.code + ' ' }}</strong>
                    {{ item.basicKnowledge.description }}
                </div>
            </div>                            
            <div [id]="'collapse'+item.id" class="collapse" [attr.aria-labelledby]="'heading-'+item.id" data-parent="#accordionSelected">
                <div class="card-body">
                    <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.specificCompetence"><strong>{{ item.evaluationCriteria.specificCompetence.code + ' ' }}</strong>{{ item.evaluationCriteria.specificCompetence.title }}</div>
                    <div *ngIf="item.evaluationCriteria && item.evaluationCriteria.code"><strong>{{ item.evaluationCriteria.code + ' ' }}</strong>{{ item.evaluationCriteria.title }}</div>
                </div>
            </div>
          </div>
      </div>
      <!-- accordion end --> 
      <div *ngIf="selectedCurriculums && selectedCurriculums.length == 0" class="text-center">
        {{ 'OPTIONS.NON_RESULTS' | translate }}
      </div>
    </div>
  </div>
</ng-template>
  
<shared-modal
*ngIf="curriculumModal == true"
(close)="curriculumModal = false"
[optClose]="false"
[scrollable]="true"
[title]="'EXAMS.PREVIEW.ASSOCIATE_KNOWLEDGES' | translate"
[contentTemp]="curriculumModalContent">
</shared-modal>

<ng-template #downloadModalContent>
  <div class="loader animated fadeIn rounded" *ngIf="loading">
    <img src="./assets/icons/spinner-solid.svg" />
  </div>
  <div class="row">
    <div class="col-12">
      <h4>{{ "EXAMS.PREVIEW.SELECT_DOWNLOAD_TYPE" | translate }}</h4>
      <br />
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <div class="pattern_item d-flex align-items-center">
            <shared-radiobutton (click)="downloadType = 'pdf'"
              [check]="downloadType == 'pdf'"
              [config]="'#f3075f'">
            </shared-radiobutton>
            <span class="ml-2 pointer" (click)="downloadType = 'pdf'">{{ 'EXAMS.PREVIEW.PDF_LABEL' | translate }}</span>
          </div>
        </div>
        <div class="col-md-6">    
          <div class="pattern_item d-flex align-items-center">
            <shared-radiobutton (click)="downloadType = 'word'"
              [check]="downloadType == 'word'"
              [config]="'#f3075f'">
            </shared-radiobutton>
            <span class="ml-2 pointer" (click)="downloadType = 'word'">{{ 'EXAMS.PREVIEW.WORD_LABEL' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br /><br /><br />
  <div class="row">
    <div class="col-6">
      <button class="btn_grey" (click)="downloadModal=false;downloadType=null;">
        {{ 'EXAMS.PREVIEW.CANCEL' | translate }}
      </button>
    </div>
    <div class="col-6">
      <button class="btn_grey" [disabled]="!downloadType" (click)="downloadFile();">
        {{ 'EXAMS.PREVIEW.DOWNLOAD' | translate }}
      </button>
    </div>
  </div>
</ng-template>
  
<shared-modal
*ngIf="downloadModal == true"
(close)="downloadModal=false;downloadType=null;"
[optClose]="false"
[title]="'EXAMS.PREVIEW.SELECT_TYPE' | translate"
[contentTemp]="downloadModalContent">
</shared-modal>