// Component Dependencies
import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { ExamService } from '../../data/services/exam.service';
import { QuestionService } from '../../data/services/question.service';
import { UnitService } from '../../data/services/unit.service';
import { ResourceService } from '../../data/services/resource.service';
import { ConfigService } from '../../data/services/config.service';
import { NotificationService } from '../../data/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../../data/services/commons.service';

// Models
import { Exam } from '../../data/models/exam.model';
import { Unit } from '../../data/models/unit.model';
import { Book } from '../../data/models/book.model';

@Component({
  selector: 'exam-generator',
  templateUrl: './exam-generator.component.html',
  styleUrls: ['./exam-generator.component.css']
})

export class ExamGeneratorComponent implements OnInit, AfterViewChecked, OnDestroy {
  // Routes
  routes = [
    { label: "Inicio", url: "/home"},
    { label: "Listado de pruebas de evaluación", url: "/exam"},
    { label: "Crear prueba", url: "/exam/generator"}
  ];

  // Main Data
  userRegion: any;
  exam: Exam = new Exam();

  // Auxiliar data
  region = '';
  selectedGroup: any;
  books: Book[] = [];
  units: Unit[] = [];

  // Selected data
  selectedType = true;

  selectedBook: any = null;
  bookDropdown: boolean;
  bookConfig: any = {};

  selectedUnit: any = null;
  unitDropdown: boolean;
  unitConfig: any = {};

  questionQuantity = 0;

  unitsSelected: Unit[] = [];
  unitsIdsSelected: Unit[] = [];
  totalUnits = 0;
  unitPage = 1;
  unitOffset = 7;
  offset = 0;
  sort = 'id';
  dirSort = 'asc';

  searchISBN = '';

  // View variables
  loading = false;
  formValidate = null;

  configSubscribe;
  unitsSubscribe;

  constructor (
    private examService: ExamService,
    private questionService: QuestionService,
    private unitService: UnitService,
    public notificationService: NotificationService,
    public translate: TranslateService,
    private router: Router,
    public config: ConfigService,
    public common: CommonsService
  ) {
    this.checkIfSessionCache();
    this.userRegion = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).region : null;
  }

  ngOnInit() {
    localStorage.removeItem('wizardQuestions');
    localStorage.removeItem('examGenerated');
    localStorage.removeItem('automaticQuestions');
    this.examService.questionsPrecharged = [];
    this.examService.examGenerated = null;
    this.examService.automaticQuestions = null;
    if (this.config.selectedGroup.id) {
      this.selectedGroup = this.config.selectedGroup;
      this.getUnits();
    }
    this.configSubscribe = this.config.subjectUpdated.subscribe((data) => {
      this.selectedGroup = this.config.getGroup();
      this.unitsSelected = [];
      this.exam.units = [];
      this.getUnits();
    });
    this.region = JSON.parse(localStorage.getItem('user')).region.title;
    this.exam.units = [];
  }

  ngAfterViewChecked() {
    if ((localStorage.getItem('previusPage').includes('exam')) && (localStorage.getItem('nextStep') === 'true')) {
      this.newEnjoy();
    }
  }

  newEnjoy() {
    //event, text, next, hideArrow, shape, timeout, scroll, nextButton
    this.config.enjoyhintSteps = [
      this.config.completeStep('next .step-exam3', 'La herramienta permite crear pruebas de evaluación de dos formas: <br/> <ul><li>- Manualmente, añadiendo tú las actividades que aparecen en el listado.</li><li>- Automáticamente.</li></ul> <br/> La herramienta genera aleatoriamente una prueba siguiendo las indicaciones que has establecido inicialmente.',true, false, '', 600, {class: '.step-exam3', type: true}),
      this.config.completeStep('next .hidden-wizard', 'Sea cual sea la opción elegida, luego podrás añadir más actividades o eliminar alguna si así lo consideras.',true, true, '', 0),
      this.config.completeStep('next .step-exam4', 'Establece un nombre para la prueba de evaluación, que te permita identificarla posteriormente con facilidad.', true, false, '', 0),
      this.config.completeStep('next .step-exam5', 'Puedes elegir de qué unidades quieres que te genere la prueba y cuántas actividades deseas que contenga (si eliges la opción «Crear actividades de forma automática»).', true, false, '', 0),
      this.config.completeStep('next .hidden-wizard', 'La propuesta que te genera la herramienta llevará actividades ya asociadas a la parte del currículo correspondiente.', true, true, '', 0),
      this.config.completeStep('click .step-exam6', 'Pulsa en «Siguiente» para generar la prueba.', false, false, '', 0, {class: '.step-exam6', type: true}),
    ];
    this.config.newEnjoy();
  }

  getUnits(queryArray = [], filterArray = [], limit = 99, offset = 0 ) {
    this.units = [];
    filterArray = [
      { param: 'subjectMetadata', value: this.selectedGroup.subjectMetadata }
    ];
    if (this.config.selectedGroup.book && this.config.selectedGroup.book > 0) {
      filterArray.push({ param: 'book', value: this.config.selectedGroup.book });
    }
    this.unitsSubscribe = this.unitService.getUnits(queryArray, filterArray, this.sort, this.dirSort, limit, offset)
    .subscribe(data => {
      for (const unit of data.results) {
        unit.number = Number(unit.number);
        this.units.push(unit);
      }
      this.totalUnits = data.results.length;
    });
  }

  getIndexFilter(arr, param) {
    let index = -1;
    for (let i = 0; i < arr.length; i ++) {
      if (arr[i].param === param) {
        index =  i;
      }
    }
    return index;
  }

  searchUnits(event) {
    const offset = event.page && event.limit ? (event.page * event.limit) - event.limit : 0;
    this.getUnits(event.query, event.filter, event.limit, offset);
  }

  generateExam(exam): void {
    let units = '';
    let limit = 1;

    limit = this.questionQuantity > 0 ? this.questionQuantity : 1;
    units = this.unitsSelected && this.unitsSelected.length > 0 ? units.concat(this.generateStringArray(this.unitsSelected)) : '';

    exam.area = this.config.selectedSubject ? this.config.selectedSubject.title : null;
    exam.classGroup = this.config.selectedGroup ? this.config.selectedGroup.title : null;

    if (this.selectedType === true) {
      localStorage.setItem('automaticQuestions', JSON.stringify(
        {
          group: this.selectedGroup.id,
          units: units,
          limit: limit,
          quantity: this.questionQuantity
        }
      ));
      localStorage.setItem('examGenerated', JSON.stringify(exam));
      this.examService.automaticQuestions = this.selectedType;
      this.examService.examGenerated = exam;
      this.router.navigate(['/exam', 'create']);
    } else if (this.selectedType === false ) {
      localStorage.setItem('examGenerated', JSON.stringify(exam));
      this.examService.examGenerated = exam;
      this.router.navigate(['/exam', 'create']);
    }
  }

  generateStringArray(arr) {
    let str = '';
    for (const i of arr) {
      str = arr.indexOf(i) < (arr.length - 1) ? str.concat(i.id + ',') : str.concat(i.id);
    }

    return str;
  }

  checkIfSessionCache() {
    if (JSON.parse(sessionStorage.getItem('examSessionCache'))) {
      if (JSON.parse(sessionStorage.getItem('examSessionCache')).exam.id) {
        this.router.navigate(['exam', JSON.parse(sessionStorage.getItem('examSessionCache')).exam.id]);
      } else {
        this.router.navigate(['exam', 'create']);
      }
    }
  }

  addUnit(unit) {
    this.unitsIdsSelected = [];

    if (!this.checkIfIsInArray(this.unitsSelected, unit, 'id')) {
      const newUnit = Object.assign({}, unit);
      this.unitsSelected.push(newUnit);
    } else {
      this.unitsSelected.splice(this.getIndexOf(this.unitsSelected, unit, 'id'), 1);
    }

    this.exam.units = [];
    for (const unitSel of this.unitsSelected) {
      const newUnit: any = Object.assign({}, unitSel);
      this.unitsIdsSelected.push(newUnit.id);
      this.exam.units.push(newUnit);
    }
  }

  checkIfIsInArray(array, item, param) {
    for (const i of array) {
      if (i[param] === item[param]) {
        return true;
      }
    }
    return false;
  }


  validateForm(exam) {
    if (document.querySelector("body.on-enjoy")) {
      const examWizard = {
        title:'Nombre de la prueba',
        area: this.config.selectedSubject ? this.config.selectedSubject.title : null,
        classGroup: this.config.selectedGroup ? this.config.selectedGroup.title : null,
        units: [{id: this.units[0].id}]
      };
      const activitiesWizard = [
        {
          content: "<p>Contenido de la actividad</p>",
          curriculums: [],
          grade: 
          "1.º ESO",
          id: 0,
          numActivity: 1,
          title: "Actividad 1",
          unitNumber: "1"
        }
      ];
      localStorage.setItem('wizardQuestions', JSON.stringify(activitiesWizard));
      localStorage.setItem('examGenerated', JSON.stringify(examWizard));
      this.examService.questionsPrecharged = activitiesWizard;
      this.examService.examGenerated = exam;
      this.router.navigate(['/exam', 'create']);
      return;
    }

    this.formValidate = false;
    if (
      (exam && exam.title && exam.title.length > 0) &&
      (this.questionQuantity > 0 || !this.selectedType) &&
      (this.unitsSelected && this.unitsSelected.length > 0)
    ) {
      this.formValidate = true;
      this.generateExam(exam);
    } else {
      this.notificationService.setNotification(this.translate.instant('MESSAGES.VALIDATE_NOTIF'), 'warning', 6000);
    }
  }

  removeUnitPage() {
    if (this.unitPage > 1) {
      this.unitPage = this.unitPage - 1;
    }
  }

  addUnitPage() {
    this.unitPage = this.unitPage + 1;
  }

  getIndexOf(array, item, param) {
    let index = 0;
    for (let i = 0; i < array.length; i ++) {
      if (array[i][param] === item[param]) {
        index = i;
      }
    }
    return index;
  }

  ngOnDestroy(): void {
    if(this.configSubscribe) {this.configSubscribe.unsubscribe()}
    if(this.unitsSubscribe) {this.unitsSubscribe.unsubscribe()}
  }
}
